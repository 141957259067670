<template>
  <div id="haya-general-search">
    <aRow class="arow">
      <aCol class="acol" :span="2">
        <img
          v-if="!isLoadingResults"
          src="@/assets/images/dashboard/haya-search.png"
          alt="search"
          width="20"
        />

        <aIcon v-if="isLoadingResults" class="f20 cgray" type="loading" />
      </aCol>
      <aCol class="acol travel-input" :span="22">
        <aInput
          class=""
          v-model="searchTerm"
          placeholder="Busca global"
          allow-clear
        />
      </aCol>
    </aRow>

    <div class="results" v-if="resultsList.length">
      <div
        v-for="(data, i) in resultsList"
        :key="i"
        @click="onClickResult(data)"
      >
        <template v-if="data.module === 'cliente'">
          <aRow class="simple-row result-row arow">
            <aCol class="value acol" :span="20">
              <template v-if="data.person_type === 'Pessoa Física'">
                <div
                  class="truncate upper"
                  :title="`${data.first_name} ${data.last_name}`"
                >
                  {{ data.id }} - {{ data.first_name }} {{ data.last_name }}
                </div>
              </template>

              <template v-if="data.person_type === 'Pessoa Jurídica'">
                <div class="truncate upper" :title="`${data.trading_name}`">
                  {{ data.id }} - {{ data.trading_name }}
                </div>
              </template>
            </aCol>
            <aCol class="module acol" :span="4"> Cliente </aCol>
          </aRow>
        </template>

        <template
          v-if="data.module === 'contrato' || data.module === 'contrato extra'"
        >
          <aRow class="simple-row result-row arow">
            <aCol class="value acol" :span="19">
              <template v-if="data.person_type === 'Pessoa Física'">
                <div
                  class="truncate upper"
                  :title="`${data.first_name} ${data.last_name}`"
                >
                  {{ data.customer_id }} - {{ data.first_name }}
                  {{ data.last_name }}
                </div>
              </template>

              <template v-if="data.person_type === 'Pessoa Jurídica'">
                <div class="truncate upper" :title="`${data.trading_name}`">
                  {{ data.customer_id }} - {{ data.trading_name }}
                </div>
              </template>

              <template v-if="data.person_type === null">
                SEM ID DO CLIENTE
              </template>
            </aCol>
            <aCol class="module acol a-right" :span="5"> Contrato </aCol>
            <aCol class="extra acol mt-10" :span="18">
              <aRow class="arow pr-20" type="flex" justify="space-between">
                <aCol class="acol"
                  ><b>{{ data.id }}</b> <a-divider type="vertical" />
                  <a-icon type="tag" />
                  {{ data.status | formatContractStatus }}</aCol
                >
                <aCol v-if="data.contracted_services" class="acol">
                  <img
                    v-if="data.contracted_services.includes('hotel')"
                    src="@/assets/images/dashboard/search-hotel.png"
                    alt="search"
                    width="13"
                  />

                  <img
                    v-if="data.contracted_services.includes('flight')"
                    src="@/assets/images/dashboard/search-flight.png"
                    width="13"
                    alt="search"
                  />

                  <img
                    v-if="data.contracted_services.includes('service')"
                    src="@/assets/images/dashboard/search-service.png"
                    alt="search"
                  />
                </aCol>
              </aRow>
            </aCol>
            <aCol class="module acol a-right mt-10" :span="6">
              {{ data.created | formatMultiDateTimesToDate }}
            </aCol>
          </aRow>
        </template>

        <template v-if="data.module === 'venda'">
          <aRow class="simple-row result-row arow">
            <aCol class="value acol" :span="19">
              <template v-if="data.person_type === 'Pessoa Física'">
                <div
                  class="truncate upper"
                  :title="`${data.first_name} ${data.last_name}`"
                >
                  {{ data.customer_id }} - {{ data.first_name }}
                  {{ data.last_name }}
                </div>
              </template>

              <template v-if="data.person_type === 'Pessoa Jurídica'">
                <div class="truncate upper" :title="`${data.trading_name}`">
                  {{ data.customer_id }} - {{ data.trading_name }}
                </div>
              </template>
            </aCol>
            <aCol class="module acol a-right" :span="5"> Venda </aCol>
            <aCol class="extra acol mt-10" :span="18">
              <aRow class="arow pr-20" type="flex" justify="space-between">
                <aCol class="acol"
                  ><b>{{ data.id }}</b> <a-divider type="vertical" />
                  <a-icon type="tag" /> {{ data.status }}</aCol
                >
                <aCol v-if="data.contracted_services" class="acol">
                  <img
                    v-if="data.contracted_services.includes('hotel')"
                    src="@/assets/images/dashboard/search-hotel.png"
                    alt="search"
                    width="13"
                  />

                  <img
                    v-if="data.contracted_services.includes('flight')"
                    src="@/assets/images/dashboard/search-flight.png"
                    width="13"
                    alt="search"
                  />

                  <img
                    v-if="data.contracted_services.includes('service')"
                    src="@/assets/images/dashboard/search-service.png"
                    alt="search"
                  />
                </aCol>
              </aRow>
            </aCol>
            <aCol class="module acol a-right mt-10" :span="6">
              {{ data.created | formatMultiDateTimesToDate }}
            </aCol>
          </aRow>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import customerMixins from "@/components/customers/mixins/customerMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "NavigationGeneralSearch",
  mixins: [customerMixins, formatThings],
  data() {
    return {
      searchTerm: "",
      resultsList: [],
      isLoadingResults: false,
    };
  },
  created() {
    // Bind debounce to getGlobalResults
    this.debouncedSearch = _.debounce(this.getGlobalResults, 300);
  },
  watch: {
    // Watch for changes to searchTerm
    searchTerm(newValue) {
      if (newValue.length >= 3) {
        this.debouncedSearch();
      } else {
        this.resultsList = []; // Clear results if searchTerm is less than 3
      }
    },
  },
  methods: {
    async getGlobalResults() {
      this.isLoadingResults = true;
      try {
        const { data } = await this.$http.get(
          `/general/global-search?searchTerm=${this.searchTerm}`
        );

        this.resultsList = data.data;
        return data.data;
      } catch (e) {
        console.error(e);
        this.resultsList = [];
      } finally {
        this.isLoadingResults = false;
      }
    },
    onClickResult(data) {
      if (data.module === "venda") {
        this.$router.push(`/sales/list?id=${data.id}&open-modal=true`);
      }
      if (data.module === "contrato" || data.module === "contrato extra") {
        this.$router.push(`/contracts/list?open-id=${data.id}&open-modal=true`);
      }
      if (data.module === "cliente") {
        this.$router.push(`/customers/edit/${data.id}`);
      }

      this.searchTerm = "";
      this.resultsList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
$textColor: #a5a5a5;
$borderColor: #e0e0e0;

#haya-general-search {
  width: 355px;
  padding: 0 10px;

  .arow {
    padding: 0;
  }

  .acol {
    padding: 0;
    border: 0 !important;
  }

  .travel-input input {
    border-bottom-width: 1px !important;
  }

  .results {
    width: 100%;
    position: absolute;
    background: #fff;
    left: 0;
    top: 63px;
    border: 1px solid $borderColor;
    line-height: 1;
    max-height: 300px;
    overflow: auto;

    .result-row {
      padding: 16px 16px;
      border-bottom: 1px solid $borderColor;
      font-size: 13px;
      cursor: pointer;
      transition: 0.3s;

      .truncate {
        max-width: 250px;
      }

      &:hover {
        background: #f5f5f5;
      }

      .acol {
        line-height: 1;
        background: transparent;
      }

      .value {
        color: #fb417d;
        font-weight: 600;
      }

      .extra {
        font-weight: 3 00;
        color: $textColor;
        font-size: 12px;
      }

      .module {
        color: $textColor;
        font-weight: 300;
        font-size: 12px;
        text-align: right;
      }
    }
    .loading-results {
      height: 40px;
      padding: 10px;
    }
  }
}
</style>
